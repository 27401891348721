import React from "react";
import { CheckboxWithLabel } from "formik-material-ui";
import { FastField } from "formik";

const FtpUpdateEnabled = () => (
  <FastField
    component={CheckboxWithLabel}
    type="checkbox"
    name="iotFtpUpdateEnabled"
    color="primary"
    Label={{
      label: "FTP Update"
    }}
  />
);

export default FtpUpdateEnabled;
