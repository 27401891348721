import React, { memo } from "react";
import { Grid, Box, Avatar, Chip } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";

import I18nText from "../../../elements/I18nText";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 4,
    marginRight: theme.spacing(1)
  }
}));

const Legend = memo(
  ({
    connectedSensors,
    excludedSensorIds,
    showMarkers,
    onToggleMarkers,
    onToggleSensor,
    onToggleTemperature,
    showTemperature,
    hasTemperatureData,
    isIotDevice
  }) => {
    const theme = useTheme();
    const classes = useStyles();

    const markerAvatarLabelKey = showMarkers ? "show" : "hide";

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Box flex="0 0 80px">
              <I18nText>devices.process.legend.sensors</I18nText>
            </Box>
            <Box flexGrow={1}>
              {connectedSensors.map(sensor => (
                <Chip
                  classes={classes}
                  style={{
                    color:
                      excludedSensorIds.indexOf(sensor.id) === -1
                        ? theme.palette.primary.main
                        : theme.palette.grey.A100
                  }}
                  avatar={
                    <Avatar
                      style={{
                        color: theme.palette.common.white,
                        backgroundColor:
                          excludedSensorIds.indexOf(sensor.id) === -1
                            ? theme.custom.colors.sensors[sensor.sequence]
                            : theme.palette.grey.A100
                      }}
                    >
                      {sensor.sequence}
                    </Avatar>
                  }
                  size="small"
                  key={sensor.id}
                  label={sensor.name}
                  onClick={onToggleSensor(sensor.id)}
                  variant="outlined"
                />
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Box flex="0 0 80px">
              <I18nText>devices.process.legend.showMarkers.label</I18nText>
            </Box>
            <Box flexGrow={1}>
              <Chip
                classes={classes}
                style={{
                  color: showMarkers
                    ? theme.palette.primary.main
                    : theme.palette.grey.A100
                }}
                size="small"
                label={
                  <I18nText>{`devices.process.legend.showMarkers.${markerAvatarLabelKey}`}</I18nText>
                }
                onClick={onToggleMarkers}
                variant="outlined"
              />
            </Box>
          </Box>
        </Grid>
        {hasTemperatureData && (
          <>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Box flex="0 0 80px">
                  <I18nText>devices.process.temperature.title</I18nText>
                </Box>
                <Box flexGrow={1}>
                  <Chip
                    classes={classes}
                    style={{
                      color: theme.palette.primary.main
                    }}
                    size="small"
                    key={0}
                    label={
                      showTemperature === true ? (
                        <I18nText>
                          devices.process.temperature.hideTemperature
                        </I18nText>
                      ) : (
                        <I18nText>
                          devices.process.temperature.showTemperature
                        </I18nText>
                      )
                    }
                    onClick={onToggleTemperature}
                    variant="outlined"
                  />
                  {/*))}*/}
                </Box>
              </Box>
            </Grid>
            {isIotDevice && (
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Box
                    flex="0 0 auto"
                    display="flex"
                    flexDirection="row"
                    marginTop={1}
                    gridGap={2}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <Box
                        style={{
                          width: "14px",
                          height: "14px",
                          borderRadius: "50%",
                          backgroundColor:
                            theme.custom.colors.temperature.normal,
                          padding: "8px",
                          marginBottom: "4px",
                          textAlign: "center"
                        }}
                      />
                      <Box marginLeft={1}>
                        <I18nText>devices.process.temperature.normal</I18nText>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      marginLeft={2}
                    >
                      <Box
                        style={{
                          width: "14px",
                          height: "14px",
                          borderRadius: "50%",
                          backgroundColor: theme.custom.colors.temperature.cold,
                          padding: "8px",
                          marginBottom: "4px",
                          textAlign: "center"
                        }}
                      />
                      <Box marginLeft={1}>
                        <I18nText>devices.process.temperature.cold</I18nText>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      marginLeft={2}
                    >
                      <Box
                        style={{
                          width: "14px",
                          height: "14px",
                          borderRadius: "50%",
                          backgroundColor: theme.custom.colors.temperature.hot,
                          padding: "8px",
                          marginBottom: "4px",
                          textAlign: "center"
                        }}
                      />
                      <Box marginLeft={1}>
                        <I18nText>devices.process.temperature.hot</I18nText>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
          </>
        )}
      </Grid>
    );
  }
);

export default Legend;
