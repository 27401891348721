import React, { useState } from "react";
import { IOT_VERSION } from "../../../../../domain/device";
import { useTheme } from "@material-ui/core";

const IotVersionSwitchButton = ({
  options = [IOT_VERSION.V1, IOT_VERSION.V2],
  iotSelectedVersion,
  onVersionSelected
}) => {
  const [selected, setSelected] = useState(iotSelectedVersion);
  const theme = useTheme();
  return (
    <div style={styles.container}>
      {options.map((option, index) => (
        <div
          key={option}
          onClick={() => {
            onVersionSelected(option);
            setSelected(option);
          }}
          style={{
            ...styles.option,
            backgroundColor:
              selected === option
                ? theme.custom.colors.iotSwitchButton.selected
                : theme.custom.colors.iotSwitchButton.unselected,
            color:
              selected === option
                ? theme.custom.colors.iotSwitchButton.textColor
                : "#000",
            borderTopLeftRadius: index === 0 ? "2px" : "0",
            borderBottomLeftRadius: index === 0 ? "2px" : "0",
            borderTopRightRadius: index === options.length - 1 ? "2px" : "0",
            borderBottomRightRadius: index === options.length - 1 ? "2px" : "0"
          }}
        >
          {option.toUpperCase()}
        </div>
      ))}
      <div style={styles.separator} />
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    border: "1px solid #ccc",
    borderRadius: "2px",
    overflow: "hidden",
    maxWidth: "200px"
  },
  option: {
    flex: 1,
    padding: "4px 8px",
    textAlign: "center",
    cursor: "pointer",
    fontSize: "12",
    transition: "background-color 0.3s ease"
  },
  separator: {
    position: "absolute",
    width: "1px",
    backgroundColor: "#ccc",
    height: "100%",
    zIndex: 1
  }
};

export default IotVersionSwitchButton;
