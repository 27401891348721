import React from "react";
import { Button, DialogContent } from "@material-ui/core";
import DialogTitleWithCloseIcon from "../../../elements/DialogTitleWithCloseIcon";

import FixedPositionDialog from "../../../elements/FixedPositionDialog";
import I18nText from "../../../elements/I18nText";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import BubbleHelp from "../../BubbleHelp";

const Dialog = ({
  children,
  onClose,
  onNextMessage,
  onPreviousMessage,
  isFirstMessage,
  isLastMessage
}) => {
  return (
    <FixedPositionDialog open={true} scroll="body" fullWidth maxWidth="md">
      <DialogTitleWithCloseIcon onClose={onClose}>
        <I18nText>messages.details.title</I18nText>
      </DialogTitleWithCloseIcon>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginLeft: "8px"
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Button
            variant="text"
            startIcon={<NavigateBeforeIcon />}
            onClick={onPreviousMessage}
            color="primary"
            disabled={isFirstMessage}
          >
            <I18nText>devices.common.messages.previous</I18nText>
          </Button>
          <Button
            variant="text"
            endIcon={<NavigateNextIcon />}
            onClick={onNextMessage}
            color="primary"
            disabled={isLastMessage}
          >
            <I18nText>devices.common.messages.next</I18nText>
          </Button>
        </div>
        <BubbleHelp id="devices.messages.navigation" />
      </div>
      <DialogContent>{children}</DialogContent>
    </FixedPositionDialog>
  );
};

export default Dialog;
