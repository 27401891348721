import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as ThermometerIcon } from "../../../assets/thermometer/thermometer.svg";

const useStyles = makeStyles(theme => ({
  thermostatWrapper: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1)
  },
  thermostatIcon: {
    width: 45,
    height: 45,
    fill: props =>
      props.sensorValue <= props.minThreshold
        ? theme.custom.colors.temperature.cold
        : props.sensorValue >= props.maxThreshold
        ? theme.custom.colors.temperature.hot
        : theme.custom.colors.temperature.normal
  },
  thermometerValue: {
    fontSize: 14,
    fontWeight: "bold",
    whiteSpace: "nowrap"
  }
}));

const Thermometer = ({
  sensorValue,
  minThreshold,
  maxThreshold,
  showThermometerIcon
}) => {
  const classes = useStyles({ sensorValue, minThreshold, maxThreshold });

  // hide the component if the temperature is null
  if (sensorValue === null || sensorValue === undefined) {
    return null;
  }
  return (
    <div className={classes.thermostatWrapper}>
      {showThermometerIcon && (
        <ThermometerIcon className={classes.thermostatIcon} />
      )}
      <Typography className={classes.thermometerValue}>
        {sensorValue}°C
      </Typography>
    </div>
  );
};

export default Thermometer;
