import React, { useState } from "react";
import { useField } from "formik";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  makeStyles,
  InputAdornment
} from "@material-ui/core";

import I18nText from "../../../../../../elements/I18nText";
import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";

export const IOT_MIN_TEMP = -100;
export const IOT_MAX_TEMP = 200;

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  }
}));

const IotTemp = () => {
  const classes = useStyles();

  const [{ value: tempMin }, metaMin, { setValue: setTempMin }] = useField(
    "iotTempMin"
  );

  const [{ value: tempMax }, metaMax, { setValue: setTempMax }] = useField(
    "iotTempMax"
  );

  const [enabled, setEnabled] = useState(
    !(tempMin === IOT_MIN_TEMP && tempMax === IOT_MAX_TEMP)
  );

  const [temporaryMin, setTemporaryMin] = useState(tempMin);
  const [temporaryMax, setTemporaryMax] = useState(tempMax);

  const updateMin = newMin => {
    setTemporaryMin(newMin);
    setTempMin(newMin);
  };

  const updateMax = newMax => {
    setTemporaryMax(newMax);
    setTempMax(newMax);
  };

  const updateEnabled = newEnabled => {
    if (!newEnabled) {
      setTempMin(IOT_MIN_TEMP);
      setTempMax(IOT_MAX_TEMP);
    } else {
      setTempMin(temporaryMin);
      setTempMax(temporaryMax);
    }

    setEnabled(newEnabled);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={e => {
                  updateEnabled(e.target.checked);
                }}
                checked={enabled}
                color="primary"
              />
            }
            label={
              <I18nText>{`devices.settings.configuration.iotTempEnabled`}</I18nText>
            }
          />
        </Grid>
        {enabled ? (
          <>
            <Grid item xs={12} sm={4}>
              <TextField
                classes={classes}
                type="number"
                size="small"
                value={tempMin}
                error={!!metaMin.error}
                variant="outlined"
                onChange={e => updateMin(e.target.value, 10)}
                inputProps={{ min: IOT_MIN_TEMP, max: IOT_MAX_TEMP }}
                label={
                  <I18nText>{`devices.settings.configuration.iotTempMin`}</I18nText>
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">°C</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <BubbleHelpFieldWrapper id="devices.edit.iotTempEnabled">
                <TextField
                  classes={classes}
                  type="number"
                  size="small"
                  value={tempMax}
                  error={!!metaMax.error}
                  variant="outlined"
                  onChange={e => updateMax(e.target.value)}
                  inputProps={{ min: IOT_MIN_TEMP, max: IOT_MAX_TEMP }}
                  label={
                    <I18nText>{`devices.settings.configuration.iotTempMax`}</I18nText>
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">°C</InputAdornment>
                    )
                  }}
                />
              </BubbleHelpFieldWrapper>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={8} />
        )}
      </Grid>
    </>
  );
};

export default IotTemp;
