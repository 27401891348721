const colors = {
  BRAND_PRIMARY: "#2d3A47",
  BRAND_SECONDARY: "#77b2fb",

  SUCCESS: "rgb(117,183 ,134)",
  ERROR: "rgb(219,101,101)",
  WARNING: "rgb(219,165,89)",

  SENSORS: {
    1: "#ac5a54",
    2: "#706c98",
    3: "#9f7e49",
    4: "#648872"
  },
  YEARS: {
    2004: "#3A4290",
    2005: "#579583",
    2006: "#C4523C",
    2007: "#828282",
    2008: "#929626",
    2009: "#973B78",
    2010: "#DDA701",
    2011: "#648498",
    2012: "#C97931",
    2013: "#528F4F",
    2014: "#A73737",
    2015: "#3A4290",
    2016: "#579583",
    2017: "#E08898",
    2018: "#80ABD0",
    2019: "#F8453C",
    2020: "#AABB19",
    2021: "#808080",
    2022: "#3A4290",
    2023: "#87b4e7",
    2024: "#f9a23b",
    2025: "#089c85",
    2026: "#ae6612"
  },
  MESSAGE_STATUSES: {
    ok: "#43AC6A",
    error: "#f04124",
    unknown: "#ffd633",

    received: "#ffd633",
    parsed: "#ffd633",
    parsing_failed: "#f04124",
    processed: "#43AC6A",

    finalized: "#ffd633",
    released: "#ffd633",
    gateway: "#ffd633",
    sent: "#43AC6A",
    delivered: "#43AC6A",
    sending_failed: "#f04124",
    delivery_failed: "#f04124"
  },
  CHART_TEMPERATURE: {
    normal: "#43AC6A",
    cold: "#78b3fa",
    hot: "#f04124",
    line: "#000000"
  }
};

export default colors;
