import React, { useEffect, useState } from "react";

import Dialog from "./Dialog";
import { useApi } from "../../../api";
import Details from "./Details";

const DetailsContainer = ({
  messageId,
  onClose,
  onNextClicked,
  onPreviousClicked,
  firstMessageId,
  lastMessageId
}) => {
  const api = useApi();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    api.get(`/v2/messages/${messageId}`).then(response => {
      setMessage(response.data);
    });
  }, [api, messageId]);

  return (
    <Dialog
      onClose={onClose}
      onNextMessage={onNextClicked}
      onPreviousMessage={onPreviousClicked}
      isFirstMessage={`${firstMessageId}` === messageId}
      isLastMessage={`${lastMessageId}` === messageId}
    >
      <Details message={message} />
    </Dialog>
  );
};

export default DetailsContainer;
