import { createTheme } from "@material-ui/core/styles";

import colors from "../colors";

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#f1efed"
        }
      }
    },
    MuiTableCell: {
      sizeSmall: {
        padding: "6px 12px 6px 8px"
      }
    },
    MuiButton: {
      root: {
        textTransform: "none"
      }
    }
  },
  palette: {
    text: {
      primary: colors.BRAND_PRIMARY
    },
    primary: {
      main: colors.BRAND_PRIMARY
    },
    secondary: {
      main: colors.BRAND_SECONDARY
    },
    success: {
      main: colors.SUCCESS
    },
    warning: {
      main: colors.WARNING
    },
    error: {
      main: colors.ERROR
    },
    background: {
      default: "#f1efed"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    fontSize: 12
  },
  custom: {
    colors: {
      sensors: { ...colors.SENSORS },
      years: { ...colors.YEARS },
      messages: {
        statuses: { ...colors.MESSAGE_STATUSES }
      },
      deviceStatus: {
        ok: colors.SUCCESS,
        alarm: colors.ERROR
      },
      temperature: { ...colors.CHART_TEMPERATURE },
      iotSwitchButton: {
        selected: colors.BRAND_SECONDARY,
        unselected: "#e0e0e0",
        textColor: "#ffffff",
        separator: "#cccccc"
      }
    },
    dialog: {
      margin: 10
    },
    topBar: {
      background: {
        color: colors.BRAND_PRIMARY
      },
      menuItem: {
        backgroundColorHighlight: colors.BRAND_SECONDARY
      }
    },
    deviceSideMenu: {
      backgroundColor: "#d3d3d3",
      quickNav: {
        backgroundColor: "#bcbcbc"
      },
      menuItem: {
        backgroundColorHover: "#e5e5e5",
        backgroundColorSelected: "#ffffff"
      }
    }
  }
});

export default theme;
