import React from "react";
import { Grid } from "@material-ui/core";

import { ROLES, useAuth } from "../../../../../../auth";
import ThingName from "./ThingName";
import MessageBox from "./MessageBox";
import FtpUpdateEnabled from "./FtpUpdateEnabled";
import IotType from "./IotType";
import { DeleteButton } from "../../../../../../elements/Button";
import { useTranslation } from "react-i18next";

const IotDevice = ({ hasLocationData, handleResetGpsClicked }) => {
  const { user, hasRole } = useAuth();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} md={10}>
        {hasRole(user, ROLES.MASTER) && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ThingName />
            </Grid>
            <Grid item xs={12}>
              <MessageBox />
            </Grid>
            <Grid item xs={12}>
              <IotType />
            </Grid>
            <Grid item xs={12}>
              <FtpUpdateEnabled />
            </Grid>
            {hasLocationData && (
              <Grid item xs={12}>
                <DeleteButton
                  text={t("devices.settings.configuration.gpsResetData.title")}
                  onClick={handleResetGpsClicked}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default IotDevice;
