import React, { useState } from "react";
import { useField } from "formik";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  makeStyles,
  MenuItem
} from "@material-ui/core";

import I18nText from "../../../../../../elements/I18nText";
import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";
import { IOT_VERSION } from "../../../../../../domain/device";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  }
}));

const IotAlarm = ({ idx }) => {
  const classes = useStyles();
  const [{ value: iotVersion }] = useField("iotVersion");

  const [{ value: alarmType }, _, { setValue: setType }] = useField(
    `iotAlarm${idx}Type`
  );

  const [{ value: text }, metaText, { setValue: setText }] = useField(
    `iotAlarm${idx}Text`
  );

  const [enabled, setEnabled] = useState(alarmType !== 0);

  const updateType = newType => {
    if (newType === 0) {
      setEnabled(false);
    }

    setType(newType);
  };

  const updateEnabled = newEnabled => {
    if (!newEnabled) {
      setType(0);
    } else {
      setType(1);
    }

    setEnabled(newEnabled);
  };

  let options = [2, 1];
  if (!enabled) {
    options.unshift(0);
  }
  let optionKey = "devices.settings.configuration.iotAlarmTypes";
  if (idx === 2 && iotVersion === IOT_VERSION.V1) {
    optionKey = "devices.settings.configuration.iotAlarmTypesV1";
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={e => {
                  updateEnabled(e.target.checked);
                }}
                checked={enabled}
                color="primary"
              />
            }
            label={
              <I18nText>{`devices.settings.configuration.iotAlarm${idx}Enabled`}</I18nText>
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BubbleHelpFieldWrapper id="devices.edit.iotAlarmType">
            <TextField
              disabled={!enabled}
              classes={classes}
              variant="outlined"
              value={alarmType}
              size="small"
              select
              onChange={e => updateType(e.target.value)}
            >
              {options.map(option => (
                <MenuItem key={option} value={option}>
                  <I18nText>{`${optionKey}.${option}`}</I18nText>
                </MenuItem>
              ))}
            </TextField>
          </BubbleHelpFieldWrapper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <BubbleHelpFieldWrapper id="devices.edit.iotAlarmText">
            <TextField
              classes={classes}
              size="small"
              value={text}
              error={metaText.error}
              variant="outlined"
              onChange={e => setText(e.target.value)}
              inputProps={{ pattern: "[A-Za-z0-9]*", maxLength: 15 }}
              label={
                <I18nText>{`devices.settings.configuration.iotAlarm${idx}Text`}</I18nText>
              }
            />
          </BubbleHelpFieldWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default IotAlarm;
