import React from "react";
import { useField } from "formik";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";

import IOT_TYPE from "../../../../../../domain/device/iotType";
import IotVersionSwitchButton from "../IotVersionSwitchButton";
import { IOT_VERSION } from "../../../../../../domain/device";

const IotType = () => {
  const [field, meta, helpers] = useField("iotType");
  const [iotVersionField, iotVersionMeta, iotVersionHelpers] = useField(
    "iotVersion"
  );

  const { value } = field;
  const { setValue } = helpers;

  const { value: iotVersionValue } = iotVersionField;
  const { setValue: setIotVersionValue } = iotVersionHelpers;

  const updateValue = (val, enabled) => {
    if (enabled) {
      setValue(val);
    } else {
      setValue(IOT_TYPE.STD);
    }
  };

  const handleSelectedVersion = iotVersion => {
    setIotVersionValue(iotVersion);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} container alignItems="center" spacing={2}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={e => {
                    updateValue(IOT_TYPE.GPS, e.target.checked);
                  }}
                  checked={value === IOT_TYPE.GPS}
                  color="primary"
                />
              }
              label="IoT-GPS"
            />
          </Grid>
          {value === IOT_TYPE.GPS && (
            <Grid item>
              <IotVersionSwitchButton
                iotSelectedVersion={iotVersionValue ?? IOT_VERSION.V2}
                onVersionSelected={handleSelectedVersion}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} container alignItems="center" spacing={2}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={e => {
                    updateValue(IOT_TYPE.PRO, e.target.checked);
                  }}
                  checked={value === IOT_TYPE.PRO}
                  color="primary"
                />
              }
              label="IoT-PRO"
            />
          </Grid>
          {value === IOT_TYPE.PRO && (
            <Grid item>
              <IotVersionSwitchButton
                iotSelectedVersion={iotVersionValue ?? IOT_VERSION.V2}
                onVersionSelected={handleSelectedVersion}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default IotType;
