import React, { useState } from "react";
import { useApi } from "../../../../../../api";
import useI18nSnackbar from "../../../../../../hooks/useI18nSnackbar";
import ConfirmationDialog from "../../../../../../elements/ConfirmationDialog";
import { DeleteButton } from "../../../../../../elements/Button";
import I18nText from "../../../../../../elements/I18nText";

const ResetGPSContainer = ({ deviceId, onCancel, onDeleted }) => {
  const api = useApi();
  const { enqueueSnackbar } = useI18nSnackbar();
  const [isDeleting, setDeleting] = useState(false);

  const resetGpsData = () => {
    setDeleting(true);

    api.post(`/v2/devices/${deviceId}/reset-gps-data`).then(() => {
      enqueueSnackbar("devices.settings.configuration.responses.gpsReset", {
        variant: "success"
      });

      setDeleting(false);
      onDeleted();
    });
  };

  return (
    <ConfirmationDialog
      onCancel={onCancel}
      isSubmitting={isDeleting}
      ConfirmButton={
        <DeleteButton disabled={isDeleting} onClick={resetGpsData} />
      }
    >
      <I18nText>
        devices.settings.configuration.gpsResetData.confirmation
      </I18nText>
    </ConfirmationDialog>
  );
};

export default ResetGPSContainer;
