import React, { useEffect } from "react";
import { MenuItem } from "@material-ui/core";

import I18nText from "../../../../../../elements/I18nText";
import { FullWidthSelect } from "../../../../../../elements/FormField";
import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";
import { IOT_VERSION } from "../../../../../../domain/device";
import { useField, useFormikContext } from "formik";

const IotSendInterval = React.memo(() => {
  const [{ value: iotVersion }] = useField("iotVersion");
  const [{ value: iotSendInterval }] = useField("iotSendInterval");
  const { setFieldValue } = useFormikContext();

  const options =
    iotVersion === IOT_VERSION.V1 ? [360, 480, 600, 720] : [360, 720];

  const defaultOption = options[0];

  const bubbleHelpId =
    iotVersion === IOT_VERSION.V2
      ? "devices.edit.iotSendIntervalV2"
      : "devices.edit.iotSendInterval";

  useEffect(() => {
    if (!options.includes(iotSendInterval)) {
      setFieldValue("iotSendInterval", defaultOption);
    }
  }, [iotVersion, options, iotSendInterval, setFieldValue, defaultOption]);
  return (
    <BubbleHelpFieldWrapper id={bubbleHelpId}>
      <FullWidthSelect
        fastField={false}
        variant="outlined"
        required
        label={
          iotVersion === IOT_VERSION.V2 ? (
            <I18nText>
              devices.settings.configuration.iotSendIntervalV2
            </I18nText>
          ) : (
            <I18nText>devices.settings.configuration.iotSendInterval</I18nText>
          )
        }
        name="iotSendInterval"
        inputProps={{
          id: "iot-send-interval-select"
        }}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            <I18nText>{`devices.settings.configuration.iotSendIntervalOptions.${option}`}</I18nText>
          </MenuItem>
        ))}
      </FullWidthSelect>
    </BubbleHelpFieldWrapper>
  );
});

export default IotSendInterval;
